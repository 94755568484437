import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
    //只要引用设计师和客户公共头部的都放在其他路由文件
    //‘此路由放未引用公共头部
const routes = [
    //其他端路由，例如一些公共页面
    {
        path: '/',
        redirect: '/view/home'
    },
    {
        path: '/login',
        name: '/login',
        component: () =>
            import ("../views/login"),
        meta: {
            auth: false
        }
    },
    {
        path: '/register',
        name: '/register',
        component: () =>
            import ("../views/register"),
        meta: {
            auth: false
        }
    },
    {
        path: '/forget',
        name: '/forget',
        component: () =>
            import ("../views/forget"),
        meta: {
            auth: false
        }
    },
    {
        path: '/view',
        name: '/view',
        component: () =>
            import("../views/view.vue"),
        redirect: '/view/home',
        children: [
            {
                path: '/view/home',
                name: '/view/home',
                component: () =>
                    import ("../views/home"),
                meta: {
                    auth: true
                }
            },
            {
                path: '/view/detail',
                name: '/view/detail',
                component: () =>
                    import ("../views/detail"),
                meta: {
                    auth: true
                }
            },
            {
                path: '/view/price',
                name: '/view/price',
                component: () =>
                    import ("../views/price"),
                meta: {
                    auth: true
                }
            },
            {
                path: '/view/user',
                name: '/view/user',
                component: () =>
                    import ("../views/user"),
                meta: {
                    auth: true
                }
            },
        ]
    },
]

const router = new VueRouter({
        mode: 'hash',
        base: process.env.VUE_APP_BASE_API,
        routes
    })
    // 解决两次访问相同路由地址报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default router