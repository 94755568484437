<template>
  <div id="app">
    <router-view v-if="isRouterAlive"/>
  </div>
</template>
<script>
export default {
  name: "App",
  //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
  //引用vue reload方法
  //inject: ['reload'],
  //需要的地方使用this.reload()
  provide() {
    return {
      reload: this.reload,
    };
  },
  data: () => ({
    isRouterAlive: true,
  }),
  //全局刷新方法
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
<style lang="scss">
// 解决弹窗显示 body 增加panding
body {
  padding-right: 0 !important;
}
#app {
  width: 100%;
  height: 100%;
  min-height: 100%;
}
</style>
